.tourStart {
  position: fixed;
  left: 50%;
  top: 50%;
  /* The anchor should be translated to the left by half of your step width and half height  */
  /* For example, if your tour step has dimensions of 280 × 156 px, you have to translate by (-140px, -78px) */
  transform: translate(-30ch, -156px);
}

.tour-step {
  mat-card-title .title-text {
    overflow: unset !important;
    text-overflow: unset !important;
    white-space: unset !important;
  }

  &.mat-menu-panel {
    max-width: 60ch;
    width: fit-content;
  }
}
