@use '../../partials/variables' as *;

@mixin chips {
  .mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: #e8f5fe;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: unset;
  }
}
