@use 'ag-grid-community/src/styles/ag-grid.scss';
@use 'ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin' as *;
@use 'variables' as *;

$primary-color: $xpo-blue--400;
$hover-color: $xpo-blue--100;

@mixin header-icon-background($path) {
  background: url('#{$path}') no-repeat;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  color: transparent;
  height: 10px;
  width: 10px;
}

ag-grid-angular {
  height: 100%;
  width: 100%;

  @include ag-theme-material(
    (
      'font-family': (
        'Inter',
        Arial,
        sans-serif,
      ),
      'cell-horizontal-padding': $xpo-contentSpacing,
      'header-foreground-color': $xpo-grey--900,
      'row-hover-color': $hover-color,
      'checkbox-checked-color': $primary-color,
      'header-background-color': $xpo-grey--80,
    )
  );

  .ag-header {
    border-bottom-color: transparent;
  }

  // TODO: only put cursor pointer when a grid has onRowClick or onCellClick
  .ag-row {
    cursor: pointer;
  }

  .ag-row-focus {
    background-color: $hover-color;
  }

  .ag-header-icon {
    .ag-icon {
      /* stylelint-disable max-line-length */
      &-asc {
        @include header-icon-background(
          'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"><g fill="none"><polygon fill="%23171717" points="0 7 7 0 14 7"/><polygon fill="%239B9B9B" points="0 11 7 18 14 11"/></g></svg>'
        );
      }

      &-none {
        @include header-icon-background(
          'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"><path fill="%239B9B9B" d="M19,14 L12,21 L5,14 L19,14 Z M12,3 L19,10 L5,10 L12,3 Z" transform="translate(-5 -3)"/></svg>'
        );
      }

      &-desc {
        @include header-icon-background(
          'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"><g fill="none"><polygon fill="%239B9B9B" points="0 7 7 0 14 7"/><polygon fill="%23171717" points="0 11 7 18 14 11"/></g></svg>'
        );
      }
      /* stylelint-enable max-line-length */
    }
  }
}
