@use '../../partials/variables' as *;

@mixin buttons {
  .mdc-button {
    --mdc-typography-button-font-weight: 700;
    --mdc-typography-button-letter-spacing: 0;
  }
  .mat-mdc-unelevated-button:not(:disabled) {
    --mdc-filled-button-label-text-color: #ffffff;
    &:hover,
    &.focus {
      transition: 200ms;
      background-color: #333333;

      mat-icon.mat-icon {
        color: #ffffff;
      }
    }
  }

  .mdc-button__label {
    display: flex;
    align-items: center;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    transition: 200ms;
  }
}

@mixin icon-buttons {
  .mat-mdc-icon-button.mat-mdc-button-base {
    font-size: 14px;
    white-space: nowrap;
  }
}
