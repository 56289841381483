@use 'variables' as *;
@use 'button' as *;

mat-card.mat-card {
  margin-bottom: $xpo-contentSpacing; // TODO: mayhbe remove?
  padding: $xpo-contentSpacing * 3;
}

mat-card-title.mat-card-title {
  color: $xpo-grey--950;
  font-size: $xpo-fontSize--extraLarge;
  font-weight: $xpo-fontWeight--bold;
  line-height: $xpo-lineHeight--base;
  margin: 0;
}

mat-card-content.mat-card-content {
  padding: $xpo-contentSpacing * 1.5 0;
  margin-bottom: 0;

  &.noBottomPaddingContCard {
    padding-bottom: 0;
  }
}

// only targeting the actions that are in the footer of the card, not the header actions
.mat-card >,
.mat-card-content > {
  mat-card-actions.mat-card-actions {
    display: flex;
    gap: $xpo-contentSpacing * 1.5;
    margin: 0;
    padding-top: $xpo-contentSpacing * 3;

    .mat-stroked-button {
      margin: 0;
    }

    .mat-button-base {
      @include hugeButton();
    }
  }
}
