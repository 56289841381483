@use 'variables' as *;

.mat-button-base:not(.mat-icon-button) {
  text-transform: capitalize;
  font-weight: $xpo-fontWeight--bold;

  .mat-icon {
    margin-right: $xpo-contentSpacing * 0.25;
  }

  .mat-chip-list {
    display: inline-flex;
  }

  .mat-chip {
    min-height: 22px;
    font-size: $xpo-fontSize--small;
    background-color: $xpo-blue--100;
    font-weight: $xpo-fontWeight--bold;
    color: inherit;
  }

  &.mat-flat-button {
    transition: $ngx-button-animationDuration;

    &.mat-primary:not(.mat-button-disabled) {
      &:hover,
      &.focus {
        background-color: $xpo-brand-black--900;
      }
    }
  }
}

@mixin hugeButton {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  height: 56px;
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;
}
