@use './../../partials/variables' as *;
@use '@angular/material' as mat;

@mixin checkbox {
  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 16px;
    --mdc-checkbox-selected-hover-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-checkbox-selected-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-checkbox-selected-pressed-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-checkbox-selected-focus-icon-color: rgba(0, 0, 0, 0.87);
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-checkmark-color: #ffffff;
  }
}
