@font-face {
  font-family: 'XPO-NAT';
  src: url('fonts/XPO-NAT.eot?g323j7');
  src: url('fonts/XPO-NAT.eot?g323j7#iefix') format('embedded-opentype'),
    url('fonts/XPO-NAT.woff2?g323j7') format('woff2'), url('fonts/XPO-NAT.ttf?g323j7') format('truetype'),
    url('fonts/XPO-NAT.woff?g323j7') format('woff'), url('fonts/XPO-NAT.svg?g323j7#XPO-NAT') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'XPO-NAT' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: '\ea9a';
}
.icon-instagram:before {
  content: '\ea9b';
}
.icon-twitter:before {
  content: '\ea9c';
}
.icon-weblink:before {
  content: '\ea9d';
}
.icon-advance_analytics:before {
  content: '\ea8e';
}
.icon-billing_invoices:before {
  content: '\ea91';
}
.icon-financial:before {
  content: '\ea92';
}
.icon-general:before {
  content: '\ea93';
}
.icon-inbound:before {
  content: '\ea94';
}
.icon-operational:before {
  content: '\ea95';
}
.icon-outbound:before {
  content: '\ea96';
}
.icon-self_service:before {
  content: '\ea97';
}
.icon-service_levels:before {
  content: '\ea98';
}
.icon-visibility:before {
  content: '\ea99';
}
.icon-fact_check:before {
  content: '\ead9';
}
.icon-contrast:before {
  content: '\ead8';
}
.icon-article:before {
  content: '\ead5';
}
.icon-delete_outline:before {
  content: '\ead6';
}
.icon-edit_note:before {
  content: '\ead7';
}
.icon-check-file:before {
  content: '\ead4';
}
.icon-forward_to_inbox:before {
  content: '\ead1';
}
.icon-pending:before {
  content: '\ead2';
}
.icon-web:before {
  content: '\ead3';
}
.icon-status_loading:before {
  content: '\eacf';
}
.icon-user_guide:before {
  content: '\ead0';
}
.icon-manage_search:before {
  content: '\eace';
}
.icon-password:before {
  content: '\eacb';
}
.icon-pipeline:before {
  content: '\eacc';
}
.icon-shuttle:before {
  content: '\eacd';
}
.icon-drag:before {
  content: '\eaca';
}
.icon-service_request:before {
  content: '\eac9';
}
.icon-people:before {
  content: '\eac8';
}
.icon-status:before {
  content: '\eac7';
}
.icon-event_available:before {
  content: '\eac5';
}
.icon-calendar_1:before {
  content: '\eac4';
}
.icon-tracking:before {
  content: '\eac3';
}
.icon-copy:before {
  content: '\eaba';
}
.icon-manage_notifications:before {
  content: '\eabb';
}
.icon-xpo_rep:before {
  content: '\eabc';
}
.icon-clock_fill:before {
  content: '\eabd';
}
.icon-gavel_with_clock:before {
  content: '\eabe';
}
.icon-load_search:before {
  content: '\eabf';
}
.icon-notifications_off:before {
  content: '\eac0';
}
.icon-recent_search:before {
  content: '\eac1';
}
.icon-saved_search:before {
  content: '\eac2';
}
.icon-arrow-expansion:before {
  content: '\eab8';
}
.icon-square-round-edges:before {
  content: '\eab9';
}
.icon-verified:before {
  content: '\eab7';
}
.icon-image:before {
  content: '\eab6';
}
.icon-cancel_circle:before {
  content: '\eab3';
}
.icon-check_circle:before {
  content: '\eab4';
}
.icon-add_location:before {
  content: '\eab2';
}
.icon-hourglass:before {
  content: '\eab1';
}
.icon-services:before {
  content: '\eab0';
}
.icon-add_item:before {
  content: '\eaa9';
}
.icon-add_note:before {
  content: '\eaaa';
}
.icon-add_service:before {
  content: '\eaab';
}
.icon-arrow_direction_left:before {
  content: '\eaac';
}
.icon-file_claim:before {
  content: '\eaad';
}
.icon-numbered_list:before {
  content: '\eaae';
}
.icon-watch_off:before {
  content: '\eaaf';
}
.icon-rank_down:before {
  content: '\e96d';
}
.icon-rank_up:before {
  content: '\eaa7';
}
.icon-trophy:before {
  content: '\eaa8';
}
.icon-secure-lock:before {
  content: '\eaa6';
}
.icon-warning_rounded:before {
  content: '\eaa5';
}
.icon-alert_circle:before {
  content: '\eaa4';
}
.icon-Processing-Icon:before {
  content: '\eaa3';
}
.icon-discount:before {
  content: '\eaa2';
}
.icon-info_outline:before {
  content: '\eaa1';
}
.icon-location:before {
  content: '\eaa0';
}
.icon-bids:before {
  content: '\ea9e';
}
.icon-dedicated:before {
  content: '\ea9f';
}
.icon-sort_uparrow:before {
  content: '\ea8f';
}
.icon-sort_downarrow:before {
  content: '\ea90';
}
.icon-heart-filled:before {
  content: '\ea8c';
}
.icon-heart_unfilled:before {
  content: '\ea8d';
}
.icon-visibility_off:before {
  content: '\ea8a';
}
.icon-visibility_on:before {
  content: '\ea8b';
}
.icon-hazmat1:before {
  content: '\eab5';
}
.icon-time_fill:before {
  content: '\ea7b';
}
.icon-accept:before {
  content: '\e989';
}
.icon-admin:before {
  content: '\e98a';
}
.icon-analytics:before {
  content: '\e98b';
}
.icon-apps:before {
  content: '\e98c';
}
.icon-barcode:before {
  content: '\e991';
}
.icon-baseline-place:before {
  content: '\e992';
}
.icon-bell:before {
  content: '\e993';
}
.icon-camera:before {
  content: '\e994';
}
.icon-chat:before {
  content: '\e999';
}
.icon-check-circle-filled:before {
  content: '\e99a';
}
.icon-circle-filled:before {
  content: '\e9a0';
}
.icon-circle-outlined:before {
  content: '\e9a1';
}
.icon-claims:before {
  content: '\e9a2';
}
.icon-close:before {
  content: '\e9a3';
}
.icon-date:before {
  content: '\e9a4';
}
.icon-download:before {
  content: '\e9a5';
}
.icon-edit:before {
  content: '\e9a6';
}
.icon-email:before {
  content: '\e9a7';
}
.icon-fast-forward:before {
  content: '\e9a8';
}
.icon-fast-rewind:before {
  content: '\e9a9';
}
.icon-feedback:before {
  content: '\e9aa';
}
.icon-finance:before {
  content: '\e9ab';
}
.icon-flag:before {
  content: '\e9ac';
}
.icon-go-back:before {
  content: '\e9ad';
}
.icon-info:before {
  content: '\e9ae';
}
.icon-lock:before {
  content: '\e9af';
}
.icon-logout:before {
  content: '\e9b0';
}
.icon-map-pin-1:before {
  content: '\e9b1';
}
.icon-map-pin-2:before {
  content: '\e9b2';
}
.icon-minus_circle_outline:before {
  content: '\e9b3';
}
.icon-minus-box-filled:before {
  content: '\e9b4';
}
.icon-minus:before {
  content: '\e9b5';
}
.icon-more-vert:before {
  content: '\e9b6';
}
.icon-nav:before {
  content: '\e9b7';
}
.icon-navigate:before {
  content: '\e9b8';
}
.icon-orders:before {
  content: '\e9b9';
}
.icon-pan-tool:before {
  content: '\e9ba';
}
.icon-pause-outline-circle:before {
  content: '\e9bb';
}
.icon-pause:before {
  content: '\e9bc';
}
.icon-phone:before {
  content: '\e9bd';
}
.icon-play:before {
  content: '\e9be';
}
.icon-plus-box-filled:before {
  content: '\e9bf';
}
.icon-plus-circle-outline:before {
  content: '\e9c0';
}
.icon-plus:before {
  content: '\e9c1';
}
.icon-print:before {
  content: '\e9c2';
}
.icon-profile:before {
  content: '\e9c3';
}
.icon-refresh:before {
  content: '\e9c4';
}
.icon-search:before {
  content: '\e9c6';
}
.icon-shipment:before {
  content: '\e9c7';
}
.icon-sms:before {
  content: '\e9c8';
}
.icon-square-filled:before {
  content: '\e9c9';
}
.icon-square-outlined:before {
  content: '\e9ca';
}
.icon-trash:before {
  content: '\e9cb';
}
.icon-upload:before {
  content: '\e9cc';
}
.icon-voicemail:before {
  content: '\e9cd';
}
.icon-volume-up:before {
  content: '\e9ce';
}
.icon-warning:before {
  content: '\e9cf';
}
.icon-wrench:before {
  content: '\e9d0';
}
.icon-check:before {
  content: '\e99b';
}
.icon-add-circle:before {
  content: '\e9d1';
}
.icon-add-comment:before {
  content: '\e9d2';
}
.icon-alarm:before {
  content: '\e9d3';
}
.icon-angle-double-left:before {
  content: '\e9d4';
}
.icon-angle-double-right:before {
  content: '\e9d5';
}
.icon-archive:before {
  content: '\e9d6';
}
.icon-arrow-back:before {
  content: '\e9d7';
}
.icon-arrow-collapse:before {
  content: '\e9d8';
}
.icon-arrows:before {
  content: '\e9e3';
}
.icon-attach-file:before {
  content: '\e9e4';
}
.icon-attach-money:before {
  content: '\e9e5';
}
.icon-bar-chart:before {
  content: '\e9e6';
}
.icon-bars:before {
  content: '\e9e7';
}
.icon-bell1:before {
  content: '\e9e8';
}
.icon-broken-image:before {
  content: '\e9e9';
}
.icon-calculator:before {
  content: '\e9ea';
}
.icon-calendar-today:before {
  content: '\e9eb';
}
.icon-cancel:before {
  content: '\e9ec';
}
.icon-check-circle:before {
  content: '\e9f1';
}
.icon-circle-filled1:before {
  content: '\e9f6';
}
.icon-clear:before {
  content: '\e9f7';
}
.icon-clipboard:before {
  content: '\e9f8';
}
.icon-code:before {
  content: '\e9f9';
}
.icon-cog:before {
  content: '\e9fa';
}
.icon-comment:before {
  content: '\e9fb';
}
.icon-cube:before {
  content: '\e9fc';
}
.icon-description:before {
  content: '\e9fd';
}
.icon-dialpad:before {
  content: '\e9fe';
}
.icon-done:before {
  content: '\e9ff';
}
.icon-donut-large:before {
  content: '\ea00';
}
.icon-drag-handle-horizontal:before {
  content: '\ea01';
}
.icon-drag-handle-vertical:before {
  content: '\ea02';
}
.icon-draw-polygon:before {
  content: '\ea03';
}
.icon-draw-square:before {
  content: '\ea04';
}
.icon-ellipsis:before {
  content: '\ea05';
}
.icon-error:before {
  content: '\ea06';
}
.icon-exclamation-diamond:before {
  content: '\ea07';
}
.icon-feedback1:before {
  content: '\ea08';
}
.icon-file-alt:before {
  content: '\ea09';
}
.icon-file-archive1x:before {
  content: '\ea0a';
}
.icon-file-audio:before {
  content: '\ea0b';
}
.icon-file-certificate:before {
  content: '\ea0c';
}
.icon-file-chart-line:before {
  content: '\ea0d';
}
.icon-file-chart-pie:before {
  content: '\ea0e';
}
.icon-file-check:before {
  content: '\ea0f';
}
.icon-file-code1x:before {
  content: '\ea10';
}
.icon-file-copy:before {
  content: '\ea11';
}
.icon-file-csv:before {
  content: '\ea12';
}
.icon-file-download:before {
  content: '\ea13';
}
.icon-file-edit:before {
  content: '\ea14';
}
.icon-file-excel:before {
  content: '\ea15';
}
.icon-file-exclamation:before {
  content: '\ea16';
}
.icon-file-image:before {
  content: '\ea17';
}
.icon-file-invoice-dollar:before {
  content: '\ea18';
}
.icon-file-medical-alt:before {
  content: '\ea19';
}
.icon-file-medical:before {
  content: '\ea1a';
}
.icon-file-minus:before {
  content: '\ea1b';
}
.icon-file-pdf:before {
  content: '\ea1c';
}
.icon-file-plus:before {
  content: '\ea1d';
}
.icon-file-powerpoint:before {
  content: '\ea1e';
}
.icon-file-spreadsheet:before {
  content: '\ea1f';
}
.icon-file-times:before {
  content: '\ea20';
}
.icon-file-user:before {
  content: '\ea21';
}
.icon-file-video:before {
  content: '\ea22';
}
.icon-file-word:before {
  content: '\ea23';
}
.icon-file:before {
  content: '\ea24';
}
.icon-filter-list:before {
  content: '\ea25';
}
.icon-flag1:before {
  content: '\ea26';
}
.icon-fullscreen-exit:before {
  content: '\ea27';
}
.icon-fullscreen:before {
  content: '\ea28';
}
.icon-help:before {
  content: '\ea2a';
}
.icon-history:before {
  content: '\ea2b';
}
.icon-info1:before {
  content: '\ea2c';
}
.icon-input-checked:before {
  content: '\ea2d';
}
.icon-input-indeterminate:before {
  content: '\ea2e';
}
.icon-input-radio-empty:before {
  content: '\ea2f';
}
.icon-input-radio-filled:before {
  content: '\ea30';
}
.icon-input-unchecked:before {
  content: '\ea31';
}
.icon-insert-link:before {
  content: '\ea32';
}
.icon-layers:before {
  content: '\ea33';
}
.icon-list-alt:before {
  content: '\ea34';
}
.icon-list-ol:before {
  content: '\ea35';
}
.icon-list-ul:before {
  content: '\ea36';
}
.icon-list:before {
  content: '\ea37';
}
.icon-local-library:before {
  content: '\ea38';
}
.icon-loop:before {
  content: '\ea39';
}
.icon-mail:before {
  content: '\ea3a';
}
.icon-message:before {
  content: '\ea3b';
}
.icon-more-vert1:before {
  content: '\ea3c';
}
.icon-note-add:before {
  content: '\ea3d';
}
.icon-note:before {
  content: '\ea3e';
}
.icon-open-in-new:before {
  content: '\ea3f';
}
.icon-open-with:before {
  content: '\ea40';
}
.icon-pause-circle-filled:before {
  content: '\ea41';
}
.icon-pause1:before {
  content: '\ea42';
}
.icon-pen:before {
  content: '\ea43';
}
.icon-photo-camera:before {
  content: '\ea44';
}
.icon-pin:before {
  content: '\ea45';
}
.icon-play-circle-filled:before {
  content: '\ea46';
}
.icon-play1:before {
  content: '\ea47';
}
.icon-plus1:before {
  content: '\ea48';
}
.icon-print1:before {
  content: '\ea49';
}
.icon-priority-high:before {
  content: '\ea4a';
}
.icon-publish:before {
  content: '\ea4b';
}
.icon-query-builder:before {
  content: '\ea4c';
}
.icon-replay:before {
  content: '\ea4d';
}
.icon-reply:before {
  content: '\ea4e';
}
.icon-report-problem:before {
  content: '\ea4f';
}
.icon-satellite:before {
  content: '\ea50';
}
.icon-save:before {
  content: '\ea51';
}
.icon-search1:before {
  content: '\ea52';
}
.icon-share:before {
  content: '\ea53';
}
.icon-sort-alpha-down:before {
  content: '\ea54';
}
.icon-sort-alpha-up:before {
  content: '\ea55';
}
.icon-sort-amount-down:before {
  content: '\ea56';
}
.icon-sort-amount-up:before {
  content: '\ea57';
}
.icon-sort-arrow:before {
  content: '\ea58';
}
.icon-sort-list:before {
  content: '\ea59';
}
.icon-sort-numeric-down:before {
  content: '\ea5a';
}
.icon-sort-numeric-up:before {
  content: '\ea5b';
}
.icon-speaker-notes:before {
  content: '\ea5c';
}
.icon-star-outline:before {
  content: '\ea5d';
}
.icon-star:before {
  content: '\ea5e';
}
.icon-tachometer-alt-average:before {
  content: '\ea5f';
}
.icon-tachometer-alt-fast:before {
  content: '\ea60';
}
.icon-tachometer-alt-fastest:before {
  content: '\ea61';
}
.icon-tachometer-alt-slow:before {
  content: '\ea62';
}
.icon-tachometer-alt-slowest:before {
  content: '\ea63';
}
.icon-tachometer-alt1x:before {
  content: '\ea64';
}
.icon-tachometer-average:before {
  content: '\ea65';
}
.icon-tachometer-fast:before {
  content: '\ea66';
}
.icon-tachometer-fastest:before {
  content: '\ea67';
}
.icon-tachometer-slow:before {
  content: '\ea68';
}
.icon-tachometer-slowest:before {
  content: '\ea69';
}
.icon-tachometer:before {
  content: '\ea6a';
}
.icon-th-large:before {
  content: '\ea6b';
}
.icon-th-list1x:before {
  content: '\ea6c';
}
.icon-th:before {
  content: '\ea6d';
}
.icon-thumbtack:before {
  content: '\ea6e';
}
.icon-trash1:before {
  content: '\ea6f';
}
.icon-tune:before {
  content: '\ea71';
}
.icon-update:before {
  content: '\ea72';
}
.icon-view-module:before {
  content: '\ea73';
}
.icon-visibility1:before {
  content: '\ea74';
}
.icon-zoom-in:before {
  content: '\ea75';
}
.icon-zoom-out:before {
  content: '\ea76';
}
.icon-rush-g:before {
  content: '\ea77';
}
.icon-appointment:before {
  content: '\ea78';
}
.icon-hazmat:before {
  content: '\ea79';
}
.icon-account-circle:before {
  content: '\e900';
}
.icon-on_hold:before {
  content: '\e987';
}
.icon-arrow_up:before {
  content: '\e984';
}
.icon-cluster_pin:before {
  content: '\e985';
}
.icon-sort_arrows:before {
  content: '\e986';
}
.icon-icon_instructions:before {
  content: '\e983';
}
.icon-Package:before {
  content: '\e980';
}
.icon-Unknown:before {
  content: '\e97d';
}
.icon-Clipboard:before {
  content: '\e94f';
}
.icon-Documents:before {
  content: '\e95b';
}
.icon-Financial_Management:before {
  content: '\e968';
}
.icon-Gavel:before {
  content: '\e950';
}
.icon-Megaphone2:before {
  content: '\e952';
}
.icon-Orders:before {
  content: '\e971';
}
.icon-Orders-icon_thick:before {
  content: '\e979';
}
.icon-Orders-icons_thin:before {
  content: '\e97a';
}
.icon-Quote:before {
  content: '\e951';
}
.icon-Rail:before {
  content: '\e97f';
}
.icon-admin_filled:before {
  content: '\e962';
}
.icon-admin_settings_filled:before {
  content: '\e931';
}
.icon-admin_thin:before {
  content: '\e938';
}
.icon-bar_chart_filled:before {
  content: '\e91c';
}
.icon-bell_filled:before {
  content: '\e934';
}
.icon-bi_report:before {
  content: '\e96a';
}
.icon-claims1:before {
  content: '\e969';
}
.icon-dashboard_icon_filled:before {
  content: '\e92e';
}
.icon-mobile_hamburger_thin:before {
  content: '\e942';
}
.icon-person_filled:before {
  content: '\e91f';
}
.icon-shipment_filled:before {
  content: '\e97c';
}
.icon-signout_filled:before {
  content: '\e933';
}
.icon-tenders_filled:before {
  content: '\e927';
}
.icon-pin_location_filled:before {
  content: '\e954';
}
.icon-pin_location_thick:before {
  content: '\e956';
}
.icon-pin_location_thin:before {
  content: '\e957';
}
.icon-pin_shipment:before {
  content: '\e958';
}
.icon-pin_shipment_thin:before {
  content: '\e955';
}
.icon-pin_star:before {
  content: '\e953';
}
.icon-storm_thin:before {
  content: '\e935';
}
.icon-traffic_filled:before {
  content: '\e91b';
}
.icon-weather_filled:before {
  content: '\e920';
}
.icon-Copy:before {
  content: '\e94b';
}
.icon-Sort:before {
  content: '\e97b';
}
.icon-actions_filled:before {
  content: '\e913';
}
.icon-blind:before {
  content: '\e961';
}
.icon-calendar_filled:before {
  content: '\e922';
}
.icon-close_thin:before {
  content: '\e923';
}
.icon-convert_to_order_thin:before {
  content: '\e92f';
}
.icon-download1:before {
  content: '\e948';
}
.icon-download2:before {
  content: '\e92d';
}
.icon-download_thin:before {
  content: '\e918';
}
.icon-email1:before {
  content: '\e92c';
}
.icon-email_thin:before {
  content: '\e940';
}
.icon-eye:before {
  content: '\e960';
}
.icon-filter:before {
  content: '\e95d';
}
.icon-flag_filled:before {
  content: '\e95e';
}
.icon-icon_checklist:before {
  content: '\e978';
}
.icon-icon_exceptions:before {
  content: '\e977';
}
.icon-icon_photos:before {
  content: '\e976';
}
.icon-icon_signature:before {
  content: '\e975';
}
.icon-info_btn:before {
  content: '\e95c';
}
.icon-phone1:before {
  content: '\e949';
}
.icon-refresh1:before {
  content: '\e96b';
}
.icon-remove:before {
  content: '\e95f';
}
.icon-replace:before {
  content: '\e974';
}
.icon-search_thin:before {
  content: '\e928';
}
.icon-shopping_cart:before {
  content: '\e959';
}
.icon-star_filled:before {
  content: '\e963';
}
.icon-star_outline:before {
  content: '\e964';
}
.icon-upload1:before {
  content: '\e947';
}
.icon-zoom_in_filled:before {
  content: '\e925';
}
.icon-zoom_out_filled:before {
  content: '\e926';
}
.icon-hot:before {
  content: '\e988';
}
.icon-lock1:before {
  content: '\e982';
}
.icon-Yes:before {
  content: '\e94d';
}
.icon-add_quote:before {
  content: '\e95a';
}
.icon-check_mark_filled:before {
  content: '\e93d';
}
.icon-check_mark_thin:before {
  content: '\e93c';
}
.icon-clock_thin:before {
  content: '\e932';
}
.icon-contact_thin:before {
  content: '\e92b';
}
.icon-critical:before {
  content: '\e96c';
}
.icon-dashboard_thin:before {
  content: '\e93e';
}
.icon-exclaim_btn:before {
  content: '\e94a';
}
.icon-exclamation_filled:before {
  content: '\e945';
}
.icon-file1:before {
  content: '\e94e';
}
.icon-finance_thin:before {
  content: '\e943';
}
.icon-graphs_thin:before {
  content: '\e937';
}
.icon-ic_home:before {
  content: '\e94c';
}
.icon-invoices_filled:before {
  content: '\e93b';
}
.icon-list_thin:before {
  content: '\e944';
}
.icon-listview_thin:before {
  content: '\e91d';
}
.icon-location_filled:before {
  content: '\e92a';
}
.icon-map_thin:before {
  content: '\e914';
}
.icon-profile_thin:before {
  content: '\e939';
}
.icon-quotes_thin:before {
  content: '\e915';
}
.icon-quoteslist_thin:before {
  content: '\e917';
}
.icon-request_thin:before {
  content: '\e916';
}
.icon-search_thick:before {
  content: '\e93f';
}
.icon-shipments_thin:before {
  content: '\e91a';
}
.icon-support_icon_filled:before {
  content: '\e936';
}
.icon-truck_filled:before {
  content: '\e921';
}
.icon-ship:before {
  content: '\e965';
}
.icon-truck_check_thin:before {
  content: '\e91e';
}
.icon-route:before {
  content: '\e9c5';
}
.icon-truck:before {
  content: '\ea70';
}
.icon-Road:before {
  content: '\e96f';
}
.icon-Sea:before {
  content: '\e970';
}
.icon-Tracking:before {
  content: '\e967';
}
.icon-Megaphone:before {
  content: '\e981';
}
.icon-truck_thin2:before {
  content: '\e929';
}
.icon-truck_thin_no_check:before {
  content: '\e946';
}
.icon-intermodal-for-shipment:before {
  content: '\ea7a';
}
.icon-intermodal:before {
  content: '\ea7a';
}
.icon-CAD:before {
  content: '\e912';
}
.icon-CHF:before {
  content: '\e901';
}
.icon-CZK:before {
  content: '\e904';
}
.icon-EUR:before {
  content: '\e902';
}
.icon-GBP:before {
  content: '\e903';
}
.icon-MAD:before {
  content: '\e905';
}
.icon-PLN:before {
  content: '\e908';
}
.icon-RON:before {
  content: '\e906';
}
.icon-USD:before {
  content: '\e907';
}
.icon-filled-CAD:before {
  content: '\e909';
}
.icon-filled-CHF:before {
  content: '\e90a';
}
.icon-filled-CZK:before {
  content: '\e90d';
}
.icon-filled-EUR:before {
  content: '\e90b';
}
.icon-filled-GBP:before {
  content: '\e90c';
}
.icon-filled-MAD:before {
  content: '\e90e';
}
.icon-filled-PLN:before {
  content: '\e911';
}
.icon-filled-RON:before {
  content: '\e90f';
}
.icon-filled-USD:before {
  content: '\e910';
}
.icon-us_currency_thin:before {
  content: '\e919';
}
.icon-get-app:before {
  content: '\ea29';
}
.icon-arrow-downward:before {
  content: '\e9d9';
}
.icon-arrow-expand:before {
  content: '\e9da';
}
.icon-arrow-down:before {
  content: '\e98d';
}
.icon-arrow-left:before {
  content: '\e98e';
}
.icon-arrow-right:before {
  content: '\e98f';
}
.icon-arrow-up:before {
  content: '\e990';
}
.icon-caret-down:before {
  content: '\e995';
}
.icon-caret-left:before {
  content: '\e996';
}
.icon-caret-right:before {
  content: '\e997';
}
.icon-caret-up:before {
  content: '\e998';
}
.icon-chevron-down:before {
  content: '\e99c';
}
.icon-chevron-left:before {
  content: '\e99d';
}
.icon-chevron-right:before {
  content: '\e99e';
}
.icon-chevron-up:before {
  content: '\e99f';
}
.icon-arrow-forward:before {
  content: '\e9db';
}
.icon-arrow-from-bottom:before {
  content: '\e9dc';
}
.icon-arrow-from-left:before {
  content: '\e9dd';
}
.icon-arrow-from-right:before {
  content: '\e9de';
}
.icon-arrow-from-top:before {
  content: '\e9df';
}
.icon-arrow-upward:before {
  content: '\e9e0';
}
.icon-arrows-h:before {
  content: '\e9e1';
}
.icon-arrows-v:before {
  content: '\e9e2';
}
.icon-caret-down1:before {
  content: '\e9ed';
}
.icon-caret-left1:before {
  content: '\e9ee';
}
.icon-caret-right1:before {
  content: '\e9ef';
}
.icon-caret-up1x:before {
  content: '\e9f0';
}
.icon-chevron-down1:before {
  content: '\e9f2';
}
.icon-chevron-left1:before {
  content: '\e9f3';
}
.icon-chevron-right1:before {
  content: '\e9f4';
}
.icon-chevron-up1:before {
  content: '\e9f5';
}
.icon-carat_up_filled:before {
  content: '\e930';
}
.icon-expand_thin:before {
  content: '\e924';
}
.icon-arrow_filled:before {
  content: '\e941';
}
.icon-container_empty:before {
  content: '\eac6';
}
.icon-dryage:before {
  content: '\ea80';
}
.icon-rail_front:before {
  content: '\ea81';
}
.icon-rail-front:before {
  content: '\ea81';
}
.icon-rail_side:before {
  content: '\e93a';
}
.icon-rail-side:before {
  content: '\e93a';
}
.icon-ltl_top:before {
  content: '\ea82';
}
.icon-ltl-top:before {
  content: '\ea82';
}
.icon-ltl_side:before {
  content: '\ea83';
}
.icon-ltl-side:before {
  content: '\ea83';
}
.icon-ltl_front:before {
  content: '\ea84';
}
.icon-ltl-front:before {
  content: '\ea84';
}
.icon-tl_top:before {
  content: '\ea85';
}
.icon-tl-top:before {
  content: '\ea85';
}
.icon-tl_side:before {
  content: '\ea86';
}
.icon-tl-side:before {
  content: '\ea86';
}
.icon-tl_front:before {
  content: '\ea87';
}
.icon-tl-front:before {
  content: '\ea87';
}
.icon-lm_top:before {
  content: '\ea88';
}
.icon-lm-top:before {
  content: '\ea88';
}
.icon-lm_side:before {
  content: '\ea89';
}
.icon-lm-side:before {
  content: '\ea89';
}
.icon-sea_front:before {
  content: '\ea7c';
}
.icon-sea-front:before {
  content: '\ea7c';
}
.icon-sea_top:before {
  content: '\ea7d';
}
.icon-sea-top:before {
  content: '\ea7d';
}
.icon-air_front:before {
  content: '\ea7e';
}
.icon-air-front:before {
  content: '\ea7e';
}
.icon-air_side:before {
  content: '\ea7f';
}
.icon-air-side:before {
  content: '\ea7f';
}
.icon-air:before {
  content: '\e972';
}
.icon-air_top:before {
  content: '\e972';
}
.icon-air-top:before {
  content: '\e972';
}
.icon-air-top-1:before {
  content: '\e972';
}
.icon-air_top_1:before {
  content: '\e972';
}
.icon-air_top-2:before {
  content: '\e966';
}
.icon-air-top-2:before {
  content: '\e966';
}
.icon-sea-side:before {
  content: '\e97e';
}
.icon-sea_side:before {
  content: '\e97e';
}
.icon-tl_side1:before {
  content: '\e96e';
}
.icon-tl-side1:before {
  content: '\e96e';
}
.icon-ltl_side1:before {
  content: '\e973';
}
.icon-ltl-side1:before {
  content: '\e973';
}
