@use '../../partials/variables' as *;
@use '@angular/material' as mat;

@mixin hugeButton {
  $height: 56px;

  text-transform: capitalize;
  display: flex;
  align-items: center;
  height: $height;
  font-size: $xpo-header-fontSize--large;
  font-weight: $xpo-fontWeight--bold;

  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base {
    height: $height;
  }
  &.mat-mdc-button.mat-mdc-button-base,
  &.mat-mdc-raised-button.mat-mdc-button-base,
  &.mat-mdc-unelevated-button.mat-mdc-button-base,
  &.mat-mdc-outlined-button.mat-mdc-button-base {
    height: $height;
  }
}

@mixin smallButton {
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  align-items: center;
}

@mixin miniButton($height: 40px) {
  button {
    &.mat-mdc-icon-button {
      &.mat-mdc-button-base {
        padding: 0;
        height: fit-content;
        width: auto;
        display: flex;
        min-height: $height;
        aspect-ratio: 1;
      }
    }
    .mdc-icon-button {
      display: flex;
    }
    mat-icon.mat-icon {
      margin: auto;
    }
  }
  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    inset: 0;
    height: auto;
    width: auto;
    transform: unset;
  }
}
