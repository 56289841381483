@use './../../partials/variables' as *;

@mixin tabs {
  .mat-mdc-tab-nav-bar {
    --mdc-ripple-color: $xpo-gray--700;
  }

  .mdc-tab {
    --mdc-typography-button-letter-spacing: 0;
  }

  .mdc-tab--active .mdc-tab__text-label {
    color: $xpo-black !important;
  }

  .mat-mdc-tab .mdc-tab__text-label,
  .mat-mdc-tab-link .mdc-tab__text-label {
    color: $xpo-black;
  }
  .mat-mdc-tab-links {
    height: 100%;
  }

  .mat-mdc-tab-link.mdc-tab {
    height: 100% !important;
    min-height: 48px;
  }

  .mdc-tab__text-label {
    color: $xpo-black !important;
  }

  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    flex-grow: 0 !important;
  }
}
