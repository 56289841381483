@use 'variables' as *;

mat-slide-toggle.mat-slide-toggle {
  &.mat-checked .mat-slide-toggle-thumb {
    background-color: $xpo-white;
  }
  &.mat-checked .mat-slide-toggle-bar {
    background-color: $xpo-green--450;
  }

  .mat-slide-toggle-bar {
    width: 40px;
    height: 20px;
    border-radius: 10px;
  }

  .mat-slide-toggle-thumb-container {
    top: 0px;
  }

  &.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(20px, 0, 0);
  }
}
