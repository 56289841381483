@use '@angular/material' as mat;

@mixin formField {
  @include mat.form-field-density(-1);
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align::before {
    --mdc-typography-caption-font-size: 10.5px;
  }
  .mat-mdc-form-field {
    width: 100%;
    margin-bottom: 20px; // To avoid bottom border doesn't "disappear" and to allow space for hint/error message
  }

  .mat-focused,
  .mdc-text-field--focus {
    .mdc-notched-outline__leading {
      border-left-color: #333333 !important;
      border-bottom-color: #333333 !important;
      border-top-color: #333333 !important;
    }
    .mdc-notched-outline__notch {
      border-bottom-color: #333333 !important;
      border-top-color: #333333 !important;
    }
    .mdc-notched-outline__trailing {
      border-right-color: #333333 !important;
      border-bottom-color: #333333 !important;
      border-top-color: #333333 !important;
    }
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 2px 16px 0 !important;
  }

  .mat-mdc-form-field-flex {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined.mdc-text-field--invalid {
    outline: none;
  }

  .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
    mat-label {
      color: #333333 !important;
    }
  }

  .mat-mdc-form-field-icon-suffix {
    border-radius: 0 4px 4px 0;
  }
  .mat-mdc-form-field-bottom-align::before {
    display: none !important;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.12);
  }

  .mdc-text-field .mdc-text-field__input {
    caret-color: #333333;
  }

  .mat-mdc-form-field-icon-prefix {
    padding: 0 4px 0 8px !important;
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0 8px 0 4px !important;
  }

  .mat-mdc-form-field,
  .mat-mdc-floating-label {
    --mdc-typography-body1-letter-spacing: 0;
    --mdc-typography-body1-font-size: 14px;
  }

  .mat-mdc-form-field-infix,
  .mat-mdc-text-field-wrapper,
  .mdc-text-field {
    background-color: #ffffff;
  }
}
