// TODO: add this style into the xpoc billing library
@use './styles/material';
@use './styles/utils';
@use './styles/ag-grid';
@use './styles/ui-tour';

@use 'variables' as *;

*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  font-size: $xpo-fontSize--root;
}

html,
body {
  margin: 0;
  height: 100vh;
  overscroll-behavior: contain; // Avoid refresh on overscroll
}

a {
  text-decoration-color: $xpo-blue--800;
  color: $xpo-blue--800;
  cursor: pointer;
}

.tour-icons {
  vertical-align: text-bottom;
}

//----- TODO: move all below to the xpoc-billing library
ltlc-billing-list,
xpoc-lib-billing-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .invoice-body,
  .invoice-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  .invoice-grid {
    .invoice-list-items {
      overflow-y: auto !important;
      height: 100% !important;
    }
  }
}

ltlc-billing-payment,
xpoc-lib-billing-payment,
xpoc-lib-payment-confirmation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ltlc-billing-payment,
xpoc-lib-billing-payment {
  div.invoice-payment {
    height: 100% !important;

    div.payment-method {
      min-height: unset !important;
    }
  }
}
