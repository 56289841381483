@use 'variables' as *;

.mat-horizontal-stepper-header-container {
  width: fit-content;
  margin: auto;

  .mat-horizontal-stepper-header {
    margin: $xpo-contentSpacing * 1.5 0;
  }

  .mat-step-header {
    .mat-step-label {
      color: $xpo-grey--350;

      &-active {
        color: $xpo-grey--700;
      }

      .mat-step-text-label {
        font-size: $xpo-fontSize--small;
        font-weight: $xpo-fontWeight--extraBold;
        line-height: $xpo-fontSize--small;
      }
    }

    .mat-step-icon {
      background-color: $xpo-grey--350;

      &-selected {
        background-color: $xpo-red--600;
      }
    }
  }
}
