// To improve light house score rather than linking in <head>

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Regular.woff2') format('woff2');
  display: swap;
  font-weight: 100 400;
}
@font-face {
  font-family: 'Inter';
  src: url('./Inter-Bold.woff2') format('woff2');
  display: swap;
  font-weight: 500 800;
}
@font-face {
  font-family: 'Inter';
  src: url('./Inter-ExtraBold.woff2') format('woff2');
  display: swap;
  font-weight: 800 900;
}
