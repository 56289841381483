@use 'variables' as *;

mat-nav-list.mat-nav-list {
  .mat-list-item {
    height: 80px;

    &:not(:last-of-type) {
      border-bottom: 1px solid $xpo-grey--300;
    }

    .mat-list-item-content {
      > .mat-icon {
        padding: 0 $xpo-contentSpacing;
      }
    }
  }
}
