@use 'variables' as *;
@use 'button' as *;

mat-dialog-container.mat-dialog-container {
  padding: $xpo-contentSpacing * 2;
  .mat-dialog-title {
    $_height: 28px;

    color: $xpo-black;
    font-weight: $xpo-fontWeight--bold;
    line-height: $_height;
  }

  .mat-dialog-content {
    color: $xpo-grey--900;
    font-size: $xpo-fontSize--extraLarge;
    line-height: $xpo-lineHeight--lg;
    padding-bottom: $xpo-contentSpacing * 2;
  }

  .mat-dialog-actions {
    border-top: 1px solid $xpo-grey--300;
    padding: $xpo-contentSpacing * 2 0;

    .mat-button-base {
      @include hugeButton();
    }
  }
}
