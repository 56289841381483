@use 'variables' as *;
@use 'mixins' as *;
@use 'breakpoints' as *;

.ltlc-Utils {
  &-pageForm {
    display: flex;
    justify-content: center;
    padding: $xpo-contentSpacing $xpo-contentSpacing * 0.5;
    background-color: $xpo-grey--80;

    &--column {
      flex-direction: column;
    }
  }

  &-buttonAsLink.mat-button {
    padding: 0 $xpo-contentSpacing * 0.5;
    color: $xpo-blue--350;
  }

  &-resetButton {
    opacity: 0;
    width: 1px;
    height: 1px;
    visibility: hidden;
  }

  &-fullHeight {
    height: 100%;
  }

  &-text {
    &-secondaryColor {
      color: $xpo-grey--700;
    }
  }

  &-link {
    &--removeStyles {
      text-decoration: none;
      color: inherit;
    }
  }

  &-grid {
    display: grid;
    column-gap: $xpo-contentSpacing;
    height: fit-content;
    width: 100%;

    //Commodity Form WIDTH is changed in accordance with design specification for authenticated create rate quote
    //TODO: Confirm with UX the form patterns
    &--medium {
      max-width: $ltlc-form-maxWidth--medium;
    }

    &--large {
      max-width: $ltlc-form-maxWidth--large;
    }

    &--xLarge {
      max-width: $ltlc-form-maxWidth--xLarge;
    }

    &-spanColumnAll {
      grid-column: 1 / -1;
    }

    &-spanColumn2 {
      grid-column: span 2;
    }

    &-spanColumn3 {
      grid-column: span 3;
    }

    &-spanColumn4 {
      grid-column: span 4;
    }

    &-spanColumn5 {
      grid-column: span 5;
    }

    &-spanColumn8 {
      grid-column: span 8;
    }

    &-col-1 {
      grid-column: 1;
    }

    &-templateColumn2 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-screen-range-0_sm {
      display: block;
      grid-template-columns: 1fr !important;
      column-gap: unset;

      &-item {
        grid-column: 1 !important;
        max-width: 100% !important;
      }
    }
  }

  &-drawerContainer {
    background-color: white;
    height: 100%;

    // TODO: confirm with UX the sizing of the drawer
    // TODO: consider multiple sizes for the drawer
    > mat-drawer {
      width: 60%;
    }

    > mat-drawer-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    @include media-screen-range-0_sm {
      > mat-drawer {
        width: 100%;
      }
    }
  }

  &-form {
    &-marginTop {
      &--xSmall {
        margin-top: $xpo-contentSpacing !important;
      }

      &--small {
        margin-top: $xpo-contentSpacing * 2 !important;
      }

      &--medium {
        margin-top: $xpo-contentSpacing * 2.5 !important;
      }

      &--large {
        margin-top: $xpo-contentSpacing * 3 !important;
      }
    }

    &-array {
      &-item {
        align-items: stretch;
        background-color: $xpo-grey--25;
        display: flex;
        margin-bottom: $xpo-contentSpacing;
        margin-left: -$xpo-contentSpacing;
        padding-left: $xpo-contentSpacing;
      }
    }

    &-noInput {
      height: $xpo-contentSpacing * 2;
    }

    &-title {
      font-size: $xpo-fontSize--large;
      font-weight: $xpo-fontWeight--bold;
      margin: $xpo-contentSpacing 0;
    }

    &-actions {
      display: flex;
      gap: $xpo-contentSpacing;

      &-loading,
      &-button {
        display: flex;
        align-items: center;
        gap: $xpo-contentSpacing * 0.5;
      }
    }

    &-noButtonPadding {
      &.mat-button,
      &.mat-flat-button {
        padding: 0;
      }
    }

    &--removeBottomPadding {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }

    @include media-screen-range-0_sm {
      &-title {
        text-align: center;
      }
    }
  }

  &-border {
    &--allButTop,
    &--all {
      border: 1px solid $xpo-grey--300;
    }

    &--allButTop {
      border-top: none;
    }

    &-bottom {
      border-bottom: 1px solid $xpo-grey--300;
    }
  }

  &-agGrid {
    &--cellBorderRight {
      border-right: 1px solid $xpo-grey--300 !important;
    }

    &-textCenter {
      .ag-header-cell-label {
        justify-content: center;
      }
    }

    &-cellTextCenter {
      .ag-cell-wrapper {
        justify-content: center;
      }
    }
  }

  &-alternateTabs {
    $_tab-ink-thickness: 2px;
    $_tab-height: 44px;

    &.mat-tab-nav-bar.mat-tab-header,
    &.mat-tab-group {
      background-color: transparent;
      border-bottom: none;
      margin-top: $xpo-contentSpacing * 0.5;
      padding: 0 $xpo-contentSpacing;

      .mat-tab-list .mat-ink-bar {
        display: none;
      }

      .mat-tab-header {
        border-bottom: none;
      }

      .mat-tab-links .mat-tab-link,
      .mat-tab-label {
        background-color: $xpo-white;
        border-bottom: 4px solid $xpo-grey--25;
        border-radius: 4px;
        border-top: $_tab-ink-thickness solid transparent;
        color: $xpo-grey--950;
        font-size: $xpo-fontSize--medium;
        font-weight: $xpo-fontWeight--normal;
        height: $_tab-height;
        justify-content: flex-start;
        line-height: $xpo-lineHeight--sm;
        margin-right: $xpo-contentSpacing * 0.5;
        min-width: 0;
        opacity: 1;
        padding: $xpo-contentSpacing;

        & > .ltlcc-XpoIcon,
        & > .mat-icon {
          $_icon-size: 20px;

          font-size: $_icon-size;
          width: $_icon-size;
          height: $_icon-size;
          margin-right: $xpo-contentSpacing * 0.5;
        }

        &.mat-tab-label-active {
          border-top-color: $xpo-red--400;
          font-weight: $xpo-fontWeight--bold;
          border-bottom-color: $xpo-white;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.mat-tab-disabled {
          background-color: $xpo-grey--80;
          color: $xpo-grey--300;
        }
      }
    }

    &--inverted {
      &.mat-tab-nav-bar.mat-tab-header,
      &.mat-tab-group {
        .mat-tab-body,
        .mat-tab-links .mat-tab-link,
        .mat-tab-label {
          background-color: $xpo-grey--25;
          border-bottom-color: $xpo-white;

          &.mat-tab-label-active {
            border-bottom-color: $xpo-grey--25;
          }
        }
      }
    }
  }

  &-metric {
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0;

      &:last-of-type {
        margin-bottom: $xpo-contentSpacing * 1.5;
      }
    }

    &-table th,
    &-title {
      color: $xpo-grey--700;
      text-transform: uppercase;
    }

    &-table {
      margin: $xpo-contentSpacing $xpo-contentSpacing * 2 $xpo-contentSpacing $xpo-contentSpacing * 2;

      th {
        text-align: start;
        font-size: $xpo-fontSize--xSmall;
        vertical-align: top;
      }
    }

    &-divider.mat-divider {
      margin: 0 $xpo-contentSpacing * 2 0 $xpo-contentSpacing * 2;
    }

    &-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &--inline {
        .ltlc-Utils-metric-group:first-of-type {
          margin-left: 0;
        }

        .ltlc-Utils-metric-group:last-of-type {
          margin-right: 0;
        }
      }
    }

    &-group {
      display: flex;
      flex-direction: column;
      margin: $xpo-contentSpacing $xpo-contentSpacing * 2;
    }

    &-title {
      font-size: $xpo-fontSize--small;
      line-height: $xpo-lineHeight--small;
      margin-bottom: $xpo-contentSpacing * 0.25;

      &--detail {
        margin: 0 $xpo-contentSpacing;
        margin-top: $xpo-contentSpacing * 1.5;
      }
    }
  }

  &-spacer {
    flex: 1 1 auto;
  }

  &--ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-toolbarInput {
    display: flex;
    .mat-form-field-appearance-outline {
      font-size: $xpo-fontSize--small;

      .mat-form-field-wrapper,
      .mat-form-field-flex {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .mat-form-field-infix {
        border-top: 0;
        bottom: 2px;
        padding: 0.5em;
      }
    }
  }

  &-marginAuto {
    margin: auto;
  }

  &-dialog {
    &-noPadding {
      .mat-dialog-container {
        padding: 0;
      }
    }
  }

  &-loginButton {
    color: $xpo-white;
    width: 100%;
    background-color: $xpo-brand-red;
    font-size: $xpo-fontSize--large;
    transition: $ngx-button-animationDuration;

    &:hover,
    &.focus {
      background-color: $xpo-brand-black--900;
    }
  }

  &-stepper {
    background-color: $xpo-grey--50;

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit,
    .mat-step-header .mat-step-icon-state-number {
      background-color: $xpo-grey--300;
      color: $xpo-grey--300;
      width: 10px !important;
      height: 10px !important;
      margin-left: 8px;
    }

    .mat-vertical-stepper-header .mat-step-label {
      font-weight: $xpo-fontWeight--normal;
      color: $xpo-grey--700 !important;
    }

    .mat-vertical-content {
      padding-bottom: $xpo-contentSpacing * 0.5;
    }

    .mat-vertical-stepper-header {
      pointer-events: none !important;
    }

    .mat-vertical-stepper-content {
      visibility: visible !important;
      height: 100% !important;
    }

    &:nth-last-child(1) {
      padding-bottom: $xpo-contentSpacing * 2;
    }

    &-step {
      &-content {
        display: grid;
        flex-direction: row;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  &-new {
    display: inline-flex;
    align-items: center;
    gap: 8px;

    .mat-chip {
      min-height: 24px;
      font-size: $xpo-fontSize--small;
      background-color: $xpo-blue--200;
      font-weight: $xpo-fontWeight--bold;
      color: $xpo-blue--850;
    }

    .mat-chip-list-wrapper {
      display: block;
    }
  }
}
