@use './../material-variables' as *;
@use '../../partials/variables' as *;

@mixin datepicker {
  .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
    background-color: $xpo-red--150;
    color: $xpo-white;
    box-shadow: unset;
  }
}
