@use './../../partials/variables' as *; // TODO: Not working as reference??
@use './../material-variables/mat-button-mixins.scss' as *;

@mixin dialog {
  .mat-mdc-dialog-container {
    --mdc-dialog-subhead-color: #333333;
    --mdc-dialog-subhead-line-height: 28px;
    --mdc-dialog-subhead-weight: 700;
    --mdc-dialog-subhead-size: 24px;
    --mdc-dialog-supporting-text-color: #4a4a4a;
    --mdc-dialog-supporting-text-size: 1.285rem;
    --mdc-dialog-supporting-text-line-height: 1.714rem;
    --mdc-dialog-supporting-text-tracking: normal;
  }
  .mat-mdc-dialog-container .mdc-dialog__title {
    display: flex;
    align-items: baseline;
  }
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 2rem;
  }
  .mdc-dialog__title {
    &::before {
      display: none !important;
    }
    padding: 0 !important;
    margin: unset;
  }
  .mdc-dialog .mdc-dialog__content {
    padding: 0 !important;
  }
  .mat-mdc-dialog-actions.mdc-dialog__actions {
    .mdc-button.mat-mdc-button-base {
      @include hugeButton();
    }
  }
}
