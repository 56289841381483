@use './../../partials/variables' as *;
@use './../material-variables/mat-button-mixins.scss' as button;

@mixin card {
  .mat-mdc-card.mdc-card {
    padding: $xpo-contentSpacing * 3;
    margin-bottom: 16px;
  }

  mat-card-title.mat-mdc-card-title {
    color: $xpo-grey--950;
    font-size: $xpo-fontSize--extraLarge;
    font-weight: $xpo-fontWeight--bold;
    line-height: $xpo-lineHeight--base;
    margin: 0;
  }

  mat-card-content.mat-mdc-card-content {
    padding: $xpo-contentSpacing * 1.5 0;
    margin-bottom: 0;

    &.noBottomPaddingContCard {
      padding-bottom: 0;
    }
  }

  .mat-mdc-card-actions.mdc-card__actions {
    display: flex;
    gap: 1.5 * $xpo-contentSpacing;
    align-items: center;
    flex-flow: row wrap;
    padding-top: 3 * $xpo-contentSpacing;

    .mdc-button.mat-mdc-button-base {
      @include button.hugeButton();
    }
  }
}
