@use 'variables' as *;

mat-form-field,
.mat-form-field {
  width: 100%;

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
  &.mat-focused .mat-form-field-label {
    color: $xpo-black !important;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-width: 1px !important;
  }

  .mat-form-field-outline {
    background-color: $xpo-white;
    border-radius: 5px;
  }

  .mat-form-field-required-marker {
    color: $xpo-red--150;
  }

  mat-hint.mat-hint {
    white-space: break-spaces;
  }
}
