@use 'variables' as *;

mat-chip-list.mat-chip-list {
  mat-chip.mat-chip {
    cursor: pointer;
    mat-icon.mat-icon[matchipremove] {
      height: $xpo-fontSize--xxlarge;
      width: auto;
      font-size: $xpo-fontSize--xxlarge;
    }
  }
}
